@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");

body {
    font-family: "Inter", sans-serif;
    background-color: #333333;
    color: white;
    padding: 0;
    margin: 0;
}

* {
    -webkit-overflow-scrolling: touch;
}

html {
    scroll-behavior: smooth;
}

* {
    scroll-behavior: smooth;
}

.d-flex {
    display: flex;
}

.flex-column {
    flex-direction: column;
}

.m-auto {
    margin: auto;
}

.my-auto {
    margin-top: auto;
    margin-bottom: auto;
}

.p-relative {
    position: relative;
}

*,
div {
    box-sizing: border-box;
}
